import React, { useEffect, useState } from 'react';
import Table, { TFilter } from '@components/table';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Input from '@components/common/input';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { EUserStatus, TCreateUser, TUser } from '@xeppt/xeppt-sdk/types';
import { useAdmins } from '@hooks/api/useAdmins';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';
import { apiAdminService } from '@api';
import { toast } from 'react-toastify';
import { useLocales } from '@hooks/helpers/useLocales';
import { userStatuses } from '@const/index';
import { useDebounceValue } from 'usehooks-ts';
import Status from '@components/common/status';
import { getAdminStatus, getAdminStatusColor } from '@utils/index';

const AdminsLayout = () => {
    const { admins, filter, handleChangeAdminsFilter } = useAdmins({
        isInitialAdmins: false
    });
    const navigate = useNavigate();
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { onOpen, onClose } = useModalContext();
    const [search, setSearch] = useState('');
    const [requestSearch, setRequestSearch] = useDebounceValue('', 500);

    const handleChangeAdminStatus = (val: 'block' | 'unblock', id: string) => {
        if (val === 'block') {
            apiAdminService
                .userBlock(id)
                .then(() => {
                    requestSuccessLocale('block_admin');
                    handleChangeAdminsFilter(filter);
                })
                .catch(requestErrorLocale);
        } else if (val === 'unblock') {
            apiAdminService
                .userUnblock(id)
                .then(() => {
                    requestSuccessLocale('unblock_admin');
                    handleChangeAdminsFilter(filter);
                })
                .catch(requestErrorLocale);
        }
    };

    const columns = [
        {
            label: 'Name',
            key: 'name',
            render: (_: string, data: TUser) => `${data.profile.firstName} ${data.profile.lastName}`
        },
        {
            label: 'Email',
            key: 'email'
        },
        {
            label: 'Phone',
            key: 'phone',
            render: (_: string, data: TUser) => `+${data.phoneCode} ${data.phoneNumber}`
        },
        {
            label: 'Status',
            key: 'status',
            isOverflow: true,
            render: (val: EUserStatus, data: TUser) => (
                <Status
                    variant={getAdminStatusColor(val)}
                    isUpdate
                    updateOptions={[
                        ...(data.status === EUserStatus.ACTIVE
                            ? [
                                  {
                                      label: 'Block',
                                      value: 'block'
                                  }
                              ]
                            : []),
                        ...(data.status === EUserStatus.BLOCKED
                            ? [
                                  {
                                      label: 'Unblock',
                                      value: 'unblock'
                                  }
                              ]
                            : [])
                    ]}
                    onUpdate={(val) => handleChangeAdminStatus(val, data.id)}>
                    {getAdminStatus(val)}
                </Status>
            ),
            filterOptions: userStatuses
        },
        {
            label: 'Date created',
            key: 'createdAt',
            isSort: true,
            render: (value: string) => moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            label: 'Profile',
            key: 'id',
            render: (value: string) => (
                <Button
                    variant="outlined"
                    style={{ background: 'none' }}
                    size="small"
                    onClick={() => navigate(`${routes.admins}/${value}`)}>
                    View Profile
                </Button>
            )
        }
    ];

    useEffect(() => {
        setRequestSearch(search);
    }, [search]);

    useEffect(() => {
        if (requestSearch.length >= 3) {
            handleChangeAdminsFilter({ search });
        } else {
            handleChangeAdminsFilter({ search: undefined });
        }
    }, [requestSearch]);

    const openAdminModal = () => {
        onOpen({
            modalId: modalIds.CREATE_ADMIN_USER,
            onSubmit: (data: TCreateUser) => {
                apiAdminService
                    .createUser(data)
                    .then(() => {
                        handleChangeAdminsFilter(filter);
                        toast.success('User has been successfully created');
                        onClose();
                    })
                    .catch(requestErrorLocale);
            }
        });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Admins</Typography>
                    <Typography>{admins?.length} records found</Typography>
                </div>
                <div className={styles.actions}>
                    <Input
                        className={styles.search}
                        leftIcon="search"
                        value={search}
                        onChange={(val) => setSearch(val)}
                        placeholder="Search by admin name, email or ID"
                    />
                    <Button leftIcon="plus" variant="primary" size="small" onClick={openAdminModal}>
                        Create
                    </Button>
                </div>
            </div>
            <Table
                //@ts-ignore
                columns={columns}
                rows={admins}
                sort={{ key: 'createdAt', value: filter.order }}
                onSortChange={() => {
                    handleChangeAdminsFilter({
                        order: filter.order === 'DESC' ? 'ASC' : 'DESC'
                    });
                }}
                //@ts-ignore
                filter={filter as TFilter}
                onFilterChange={(val) => handleChangeAdminsFilter(val)}
                emptyDescription="Admins list is empty"
            />
        </div>
    );
};

export default AdminsLayout;
