import React, { FC, useEffect, useState } from 'react';
import DataInfo from '@components/data_info';
import FormField from '@components/form_field';
import { useForm } from '@hooks/helpers/useForm';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import Address from '@components/address';
import styles from './styles.module.scss';
import { TConsumer } from '@xeppt/xeppt-sdk/types';

interface IProps {
    consumer?: TConsumer;
}

const BusinessSection: FC<IProps> = ({ consumer }) => {
    const [isEditGeneral, setIsEditGeneral] = useState(false);
    const [isEditBusiness, setIsEditBusiness] = useState(false);
    const [isEditTrading, setIsEditTrading] = useState(false);
    const [isEditRegistration, setIsEditRegistration] = useState(false);
    const form = useForm({});
    const { labelLocale } = useLocales();

    useEffect(() => {
        //@ts-ignore
        if (consumer?.business) {
            //@ts-ignore
            form.reset(consumer.business);
        }
    }, [consumer]);

    return (
        <div className={styles.wrapper}>
            <DataInfo
                label="Business information"
                showEdit
                isEdit={isEditGeneral}
                form={form}
                onChangeEdit={() => setIsEditGeneral((state) => !state)}>
                <FormField
                    name="companyName"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('company_name')} />;
                    }}
                />
                <FormField
                    name="tradingName"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('trading_name')} />;
                    }}
                />
                <FormField
                    name="registrationNumber"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('registration_number')} />;
                    }}
                />
                <FormField
                    name="category"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('category')} />;
                    }}
                />
                <FormField
                    name="Size"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('Size')} />;
                    }}
                />
                <FormField
                    name="website"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('website')} />;
                    }}
                />
            </DataInfo>
            <DataInfo
                label="Registered Business Address"
                showEdit
                isEdit={isEditRegistration}
                form={form}
                full
                onChangeEdit={() => setIsEditRegistration((state) => !state)}>
                <Address isFull name="registration_address" />
            </DataInfo>
            <DataInfo
                label="Business Address"
                showEdit
                isEdit={isEditBusiness}
                form={form}
                full
                onChangeEdit={() => setIsEditBusiness((state) => !state)}>
                <Address isFull name="business_address" />
            </DataInfo>
            <DataInfo
                label="Trading Address"
                showEdit
                isEdit={isEditTrading}
                form={form}
                full
                onChangeEdit={() => setIsEditTrading((state) => !state)}>
                <Address isFull name="traiding_address" />
            </DataInfo>
        </div>
    );
};

export default BusinessSection;
