import React, { FC, useMemo } from 'react';
import { Range as RangePicker } from 'react-range';
import pointImage from '@svg/point.svg';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface IProps {
    values: number[];
    onChange: (values: number[]) => void;
    min: number;
    max: number;
    step?: number;
}

const cx = classNames.bind(styles);

const Range: FC<IProps> = ({ onChange, values, min, max, step = 1 }) => {
    const getProgressStyle = useMemo(() => {
        const firstPoint = values[0] / (max / 100);
        const secondPoint = values[1] / (max / 100);
        return {
            background: `linear-gradient(to right, var(--secondary-80) 0%, var(--secondary-80) ${firstPoint}%, var(--primary-30) ${firstPoint}%, var(--primary-30) ${secondPoint}%, var(--secondary-80) ${secondPoint}%, var(--secondary-80) 100%)`
        };
    }, [values]);

    return (
        <RangePicker
            step={step}
            min={min}
            max={max}
            values={values}
            onChange={onChange}
            renderTrack={({ props, children }) => {
                return (
                    <div className={styles.wrapper} {...props} style={getProgressStyle}>
                        {children}
                    </div>
                );
            }}
            renderThumb={({ props, isDragged, value }) => {
                return (
                    <div {...props} className={cx([styles.point, { isDragged }])}>
                        <span style={{ background: `url(${pointImage})` }}>{value}</span>
                    </div>
                );
            }}
        />
    );
};

export default Range;
