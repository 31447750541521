import React, { useEffect, useState } from 'react';
import headerLogo from '@svg/header_logo.svg';
import NavLink from '@components/common/navlink';
import { routes } from '@const/routes';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { useUserContext } from '@hooks/context/useUserContext';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Sidebar = () => {
    const { handleLogout } = useUserContext();
    const [isOpen, setIsOpen] = useState(true);

    const onToggle = () => {
        localStorage.setItem('isOpenSidebar', JSON.stringify(!isOpen));
        setIsOpen((state) => !state);
    };

    useEffect(() => {
        setIsOpen(JSON.parse(localStorage.getItem('isOpenSidebar') || 'true'));
    }, []);

    return (
        <aside className={cx([styles.wrapper, { isOpen }])}>
            <Button
                rightIcon="nav_right"
                variant="primary"
                size="normal"
                onClick={onToggle}
                className={cx([styles.toggle_button, { isOpen }])}
            />
            <div className={styles.top}>
                <div className={cx([styles.header, { isOpen }])}>
                    <img src={headerLogo} alt="" />
                    Admin Portal
                </div>
                <div className={cx([styles.navigation, { isOpen }])}>
                    <NavLink href={routes.transactions}>
                        <Icon name="list" />
                        <Typography className={cx([{ isOpen }])}>Transactions</Typography>
                    </NavLink>
                    <NavLink href={routes.consumers}>
                        <Icon name="consumer" />
                        <Typography className={cx([{ isOpen }])}>Consumers</Typography>
                    </NavLink>
                    <NavLink href={routes.admins}>
                        <Icon name="consumer" />
                        <Typography className={cx([{ isOpen }])}>Admins</Typography>
                    </NavLink>
                    <NavLink href={routes.logs}>
                        <Icon name="logs" />
                        <Typography className={cx([{ isOpen }])}>Logs</Typography>
                    </NavLink>
                    <NavLink href={routes.help_center}>
                        <Icon name="help" />
                        <Typography className={cx([{ isOpen }])}>
                            Help {isOpen && 'Center'}
                        </Typography>
                    </NavLink>
                </div>
            </div>
            <Button
                className={styles.logout}
                variant="primary"
                size="normal"
                leftIcon="upload"
                onClick={handleLogout}>
                {isOpen && 'Logout'}
            </Button>
        </aside>
    );
};

export default Sidebar;
