import { useState } from 'react';
import { TTransactionLite, TTransactionsFilter } from '@xeppt/xeppt-sdk/types/transaction';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAdminService } from '@api';
import useDebounce from '@hooks/helpers/useDebounce';
import { getDateOneMonthAgo, removeEmptyArrays } from '@utils/index';

interface IProps {
    isInitialTransactions?: boolean;
    consumerId?: string;
}

export const useTransactions = ({ consumerId, isInitialTransactions }: IProps) => {
    const [cursor, setCursor] = useState<Date>();
    const [transactions, setTransactions] = useState<TTransactionLite[]>([]);
    const [filter, setFilter] = useState<TTransactionsFilter>({
        id: undefined,
        action: undefined,
        method: undefined,
        status: undefined,
        consumerId: undefined,
        dateFrom: undefined,
        dateTo: undefined,
        amountFrom: undefined,
        search: undefined,
        amountTo: undefined,
        order: 'DESC'
    });
    const [cardTransactionsFilter, setCardTransactionsFilter] = useState({
        startDate: new Date(),
        endDate: getDateOneMonthAgo()
    });
    const debouncedFilter = useDebounce(filter, 500);

    const { data: transactionsData, handleRequest: refetchTransactions } = useApiQuery({
        method: () => {
            console.log({
                data: [
                    { ...removeEmptyArrays(filter), consumerId },
                    { cursor, limit: 100 }
                ]
            });
            return apiAdminService.getTransactions(
                { ...removeEmptyArrays(filter), consumerId },
                { cursor, limit: 100 }
            );
        },
        onSuccess: (data) => {
            if (cursor !== data.cursor && cursor) {
                setTransactions((state) => [...state, ...data.data]);
                setCursor(data.cursor);
            } else {
                setTransactions(data.data);
            }
        },
        isInitialRequest: isInitialTransactions,
        deps: [debouncedFilter, consumerId]
    });

    const onChangeFilter = (data: Partial<typeof filter>) => {
        setCursor(undefined);
        setFilter((state) => ({ ...state, ...data }));
    };

    const {
        data: transaction,
        handleRequest: getSingleTransaction,
        handleRemoveResponse: handleRemoveTransactionData
    } = useApiQuery({
        method: (id?: string) => apiAdminService.getTransactionById(id || ''),
        isInitialRequest: false
    });

    const {
        data: cardTransactions,
        handleRequest: handleRequestCardTransaction,
        handleRemoveResponse: handleRemoveCardTransactionsResponse
    } = useApiQuery({
        method: (cardId?: string) =>
            apiAdminService.getConsumerCardTransactions({
                cardId: cardId || '',
                ...cardTransactionsFilter
            }),
        isInitialRequest: false
    });

    return {
        transactions,
        filter,
        refetchTransactions,
        transactionsData,
        onChangeFilter,
        transaction,
        getSingleTransaction,
        handleRemoveTransactionData,
        cardTransactions,
        handleRemoveCardTransactionsResponse: () => {
            handleRemoveCardTransactionsResponse();
            setCardTransactionsFilter({
                startDate: new Date(),
                endDate: getDateOneMonthAgo()
            });
        },
        handleChangeCardTransactionsFilter: (value: { startDate: Date; endDate: Date }) => {
            setCardTransactionsFilter(value);
        },
        handleRequestCardTransaction,
        cardTransactionsFilter
    };
};
