import React, { FC, useRef, useState } from 'react';
import Input from '@components/common/input';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import { Country } from 'country-state-city';
import useClickOutside from '@hooks/helpers/useClickOutside';

interface IProps {
    value?: string;
    onChange?: (value: string) => void;
    label?: string;
    placeholder?: string;
    readOnly?: boolean;
    full?: boolean;
    disabled?: boolean;
    selectSize?: 'small' | 'large';
}

export function getFlagEmoji(countryCode: string) {
    return String.fromCodePoint(
        //@ts-ignore
        ...[...countryCode.toUpperCase()].map((x) => 0x1f1a5 + x.charCodeAt())
    );
}

const items = Country.getAllCountries().map((country) => ({
    label: `${getFlagEmoji(country.isoCode)} ${country.phonecode}`,
    value: country.phonecode
}));

const cx = classNames.bind(styles);

const Select: FC<IProps> = ({
    value = '',
    placeholder,
    selectSize = 'large',
    readOnly,
    disabled,
    full,
    label,
    onChange
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState<string>();
    const selectRef = useRef(null);

    const handleChange = (value: string) => {
        if (onChange) onChange(value);
        setSearch(undefined);
        setIsOpen(false);
    };

    useClickOutside({
        ref: selectRef,
        callback: () => setIsOpen(false),
        event: 'mousedown'
    });

    return (
        <div className={cx([styles.wrapper, { isFull: full }])} ref={selectRef}>
            <Input
                rightIcon="nav_down"
                value={
                    value && search === undefined
                        ? items.find((item) => item.value === value)?.label
                        : search
                }
                placeholder={placeholder}
                label={label}
                readOnly={readOnly}
                full={full}
                className={cx([{ isOpen }])}
                inputClassName={styles.input}
                onFocus={() => {
                    if (!readOnly) setIsOpen(true);
                }}
                onClickIcon={() => {
                    if (!readOnly) setIsOpen((state) => !state);
                }}
                disabled={disabled}
                onChange={(val) => {
                    if (value && search === undefined) {
                        setSearch('');
                        if (onChange) onChange('');
                    } else {
                        setSearch(val);
                    }
                }}
            />
            <div className={cx([styles.dropdown, { isOpen, isLarge: selectSize === 'large' }])}>
                {items
                    .filter((item) =>
                        item.label.toLowerCase().includes((search || '').toLowerCase())
                    )
                    .map((item, i) => (
                        <button
                            key={i}
                            onClick={() => {
                                if (!disabled) handleChange(item.value);
                            }}>
                            {item.label}
                        </button>
                    ))}
            </div>
        </div>
    );
};

export default Select;
