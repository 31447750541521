import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { errorCodes } from '@const/index';

export const useLocales = () => {
    const { t } = useTranslation(['form', 'requests', 'submit']);

    const validationLocale = (string: string) => {
        return t(`validation.${string}`, { ns: 'form' });
    };

    const labelLocale = (string: string) => {
        return t(`labels.${string}`, { ns: 'form' });
    };

    const placeholderLocale = (string: string) => {
        return t(`placeholder.${string}`, { ns: 'form' });
    };

    const requestSuccessLocale = (string: string) => {
        return t(`success.${string}`, { ns: 'requests' });
    };

    const requestErrorLocale = (e: any) => {
        if (!e?.errorCode || !errorCodes.includes(e?.errorCode)) {
            return toast.error(t('error.something_went_wrong', { ns: 'requests' }));
        }
        return toast.error(t(`error.${e?.errorCode}`, { ns: 'requests' }));
    };

    const submitLocale = (string: string) => {
        return t(string, { ns: 'submit' });
    };

    return {
        validationLocale,
        labelLocale,
        placeholderLocale,
        requestSuccessLocale,
        requestErrorLocale,
        submitLocale,
        t
    };
};
