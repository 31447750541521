import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import { Link as ReactLink } from 'react-router-dom';

interface IProps {
    to: string;
    children: ReactNode;
    className?: string;
}

const cx = classNames.bind(styles);

const Link: FC<IProps> = ({ children, to, className }) => {
    return (
        <ReactLink className={cx([styles.root, className])} to={to}>
            {children}
        </ReactLink>
    );
};

export default Link;
