import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
    amount: number;
    currency: string;
    isNegative?: boolean;
}

const PriceStatus: FC<IProps> = ({ amount, currency, isNegative = false }) => {
    return (
        <span className={!isNegative ? styles.green : isNegative ? styles.red : ''}>
            {isNegative && '-'}
            {amount.toFixed(2)}
            {currency}
        </span>
    );
};

export default PriceStatus;
