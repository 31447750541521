import React, { FC, useEffect, useRef, useState } from 'react';
import Picker from 'react-datepicker';
import './styles.scss';
import { getFirstLetter } from '@utils/index';
import { shortMonths } from '@const/dates';
import { getYears } from '@utils/date';
import { Icon } from '@components/icons';
import moment from 'moment/moment';
import { CustomInput } from '@components/date_picker/input';
import { SimpleDropdown } from '@components/date_picker/dropdown';
import { useTranslation } from 'react-i18next';

interface IProps {
    helperText?: string;
    label?: string;
    placeholder?: string;
    full?: boolean;
    error?: boolean;
    readOnly?: boolean;
    value?: string;
    maxDate?: Date;
    minDate?: Date;
    onChange?: (val: string) => void;
    disabled?: boolean;
}

const DatePicker: FC<IProps> = ({
    helperText,
    full,
    disabled = false,
    readOnly = false,
    maxDate,
    error,
    label,
    onChange,
    minDate,
    value,
    placeholder
}) => {
    const { t } = useTranslation('components', {
        keyPrefix: 'date_picker'
    });
    const ref = useRef<any>();
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [date, setDate] = useState<any>(maxDate || new Date());

    useEffect(() => {
        if (value && new Date(value).toString() !== date.toString()) {
            setDate(new Date(value));
        }
    }, [value]);

    return (
        <div className={`wrapper ${full ? 'full' : ''}`}>
            <Picker
                ref={ref}
                selected={date}
                onChange={(date) => setStartDate(date)}
                readOnly={readOnly}
                showMonthDropdown
                showYearDropdown
                shouldCloseOnSelect={false}
                renderDayContents={(day) => {
                    return <div>{day}</div>;
                }}
                startDate={maxDate}
                maxDate={maxDate}
                minDate={minDate}
                disabled={disabled}
                //@ts-ignore
                formatWeekDay={(day) => <div>{getFirstLetter(day)}</div>}
                useShortMonthInDropdown
                dateFormat="MM/DD/YYYY"
                placeholderText={t('placeholder')}
                customInput={
                    <CustomInput
                        realValue={moment(date).format('MM/DD/YYYY')}
                        label={label}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        helperText={helperText}
                        error={error}
                        full={full}
                        disabled={disabled}
                    />
                }
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    increaseYear,
                    decreaseYear,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                    prevYearButtonDisabled,
                    nextYearButtonDisabled
                }) => (
                    <div className="header_wrapper">
                        <div className="date_wrapper">
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                <Icon name="nav_left" />
                            </button>
                            <SimpleDropdown
                                value={shortMonths[moment(date).month()]}
                                items={shortMonths}
                                onChange={(value) => changeMonth(shortMonths.indexOf(value))}
                            />
                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                <Icon name="nav_right" />
                            </button>
                        </div>
                        <div className="date_wrapper">
                            <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                                <Icon name="nav_left" />
                            </button>
                            <SimpleDropdown
                                value={String(moment(date).year())}
                                items={getYears(200, true)
                                    .filter((item) => item <= new Date().getFullYear() - 18)
                                    .map((item) => String(item))
                                    .reverse()}
                                onChange={(value) => changeYear(Number(value))}
                            />
                            <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
                                <Icon name="nav_right" />
                            </button>
                        </div>
                    </div>
                )}>
                <div className="footer">
                    <button
                        onClick={() => {
                            ref.current.setOpen(false);
                        }}>
                        {t('cancel')}
                    </button>
                    <button
                        onClick={() => {
                            setDate(startDate);
                            //@ts-ignore
                            onChange && onChange(startDate);
                            ref.current.setOpen(false);
                        }}>
                        {t('ok')}
                    </button>
                </div>
            </Picker>
        </div>
    );
};

export default DatePicker;
