import React, { FC, useEffect, useState } from 'react';
import Table from '@components/table';
import styles from './styles.module.scss';
import Status from '@components/common/status';
import Typography from '@components/common/typography';
import Drawer from '@components/drawer';
import PriceStatus from '@components/common/price_status';
import Button from '@components/common/button';
import { useTransactions } from '@hooks/api/useTransactions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionStatus,
    TTransactionLite
} from '@xeppt/xeppt-sdk/types/transaction';
import {
    getTransactionAction,
    getTransactionMethod,
    getTransactionStatus,
    getTransactionStatusColor
} from '@utils/index';
import moment from 'moment';
import {
    moneyOutActions,
    transactionsMethods,
    transactionsStatuses,
    transactionsType
} from '@const/index';
import { Icon } from '@components/icons';

interface IProps {
    cardId: string;
}

const CardTransactionsSection: FC<IProps> = ({ cardId }) => {
    const { id: consumerId } = useParams<{ id: string }>();
    const [sort, setSort] = useState<{ createdAt: 'ASC' | 'DESC' }>({
        createdAt: 'ASC'
    });
    const [currentTransaction, setCurrentTransaction] = useState<TTransactionLite>();
    const { cardTransactions, handleRemoveCardTransactionsResponse, handleRequestCardTransaction } =
        useTransactions({
            consumerId,
            isInitialTransactions: false
        });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const removeQueryParams = (params: string[]) => {
        const searchParams = new URLSearchParams(location.search);
        params.forEach((param) => searchParams.delete(param));
        navigate({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    };

    useEffect(() => {
        if (cardId) {
            handleRequestCardTransaction(cardId);
        }
    }, [cardId]);

    const columns = [
        {
            label: 'Date and Time',
            key: 'createdAt',
            isSort: true,
            render: (value: string) => moment(value).format('DD.MM.YYYY HH:mm')
        },
        {
            label: 'Transaction ID',
            key: 'id'
        },
        {
            label: 'Transaction Method',
            key: 'method',
            filterOptions: transactionsMethods,
            render: (value: ETransactionMethod) => getTransactionMethod(value) //
        },
        { label: 'Consumer Name', key: 'consumerName' },
        { label: 'Consumer ID', key: 'consumerId' },
        {
            label: 'Action',
            key: 'action',
            filterOptions: transactionsType,
            render: (value: ETransactionAction) => getTransactionAction(value)
        },
        {
            label: 'Status',
            key: 'status',
            filterOptions: transactionsStatuses,
            render: (value: ETransactionStatus) => (
                <Status variant={getTransactionStatusColor(value)}>
                    {getTransactionStatus(value)}
                </Status>
            )
        },
        { label: 'Currency', key: 'currency' },
        {
            label: 'Amount',
            key: 'amount',
            render: (value: number, data: TTransactionLite) => (
                <PriceStatus
                    isNegative={moneyOutActions.includes(data.action)}
                    amount={value}
                    currency="$"
                />
            )
        },
        {
            label: 'Details',
            key: 'id',
            render: (_: string, data: TTransactionLite) => (
                <Button
                    onClick={() => {
                        setIsDrawerOpen(true);
                        setCurrentTransaction(data);
                    }}
                    variant="outlined"
                    size="small"
                    style={{ background: 'none' }}>
                    Details
                </Button>
            )
        }
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <i onClick={() => removeQueryParams(['cardId'])}>
                        <Icon name="arrow_left" width={16} height={16} />
                        Back
                    </i>
                    <Typography variant="h4">Transactions</Typography>
                </div>
            </div>
            <Table
                //@ts-ignore
                columns={columns}
                rows={cardTransactions}
                sort={{ key: 'createdAt', value: sort.createdAt }}
                onSortChange={() => {
                    setSort((state) => ({
                        createdAt: state.createdAt === 'DESC' ? 'ASC' : 'DESC'
                    }));
                }}
                emptyDescription="Transactions list is empty"
            />
            <Drawer
                className={styles.drawer}
                isOpen={isDrawerOpen && !!currentTransaction}
                width={670}
                onClose={() => {
                    setIsDrawerOpen(false);
                    handleRemoveCardTransactionsResponse();
                    setCurrentTransaction(undefined);
                }}>
                <Typography className={styles.title} variant="h5">
                    Transaction details
                </Typography>
                <div className={styles.content}></div>
            </Drawer>
        </div>
    );
};

export default CardTransactionsSection;
