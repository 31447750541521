import CreateAdminModal from '../UI/modals/create_admin';

type ModalType = {
    modalId: string;
    confirmLabel?: string;
    cancelLabel?: string;
    isHeader?: boolean;
    isFooter?: boolean;
    variant: 'medium' | 'small' | 'custom' | 'large';
    Component: React.FC;
};

const modalIds = {
    CREATE_ADMIN_USER: 'CREATE_ADMIN_USER'
};

const createAdminModal: ModalType = {
    modalId: modalIds.CREATE_ADMIN_USER,
    confirmLabel: 'create',
    cancelLabel: 'cancel',
    isHeader: true,
    isFooter: true,
    variant: 'small',
    Component: CreateAdminModal
};

const modals: ModalType[] = [createAdminModal];

export { modals, modalIds };
