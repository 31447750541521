import React, { FC, SVGAttributes } from 'react';

const LoadingSpinner: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0C8.11791 -2.24436e-08 6.27403 0.531134 4.68035 1.53234C3.08666 2.53354 1.80787 3.96417 0.990996 5.65974C0.174122 7.35531 -0.147671 9.24699 0.0626131 11.1173C0.272897 12.9876 1.00672 14.7606 2.17972 16.2324C3.35271 17.7043 4.91726 18.8152 6.69348 19.4375C8.46971 20.0598 10.3855 20.1683 12.2206 19.7503C14.0557 19.3324 15.7356 18.4051 17.0672 17.075C18.3987 15.7449 19.3279 14.066 19.7479 12.2314L17.8608 11.7994C17.5221 13.2789 16.7728 14.6327 15.699 15.7053C14.6252 16.7779 13.2705 17.5257 11.7907 17.8628C10.3109 18.1998 8.76596 18.1123 7.3336 17.6105C5.90124 17.1087 4.63957 16.2128 3.69366 15.0259C2.74774 13.839 2.15598 12.4092 1.9864 10.901C1.81683 9.39277 2.07633 7.8673 2.73506 6.49998C3.3938 5.13265 4.42502 3.97898 5.71019 3.1716C6.99535 2.36422 8.48227 1.93591 10 1.93591V0Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default LoadingSpinner;
