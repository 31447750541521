import { useState } from 'react';
import { apiAdminService } from '@api';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useParams } from 'react-router-dom';
import { TUserFilter } from '@xeppt/xeppt-sdk/types/admin';

interface IProps {
    isInitialAdmins?: boolean;
    isInitialAdmin?: boolean;
}

export const useAdmins = ({ isInitialAdmins = false, isInitialAdmin = false }: IProps) => {
    const { id } = useParams();
    const [filter, setFilter] = useState<TUserFilter>({
        search: undefined,
        status: undefined,
        order: 'ASC'
    });

    const { data: admins, handleRequest: refetchAdmins } = useApiQuery({
        method: () => apiAdminService.getUserList(filter),
        isInitialRequest: isInitialAdmins,
        deps: [filter]
    });

    const { data: admin } = useApiQuery({
        method: () => apiAdminService.getUserById(id || ''),
        condition: !!id,
        deps: [id],
        isInitialRequest: isInitialAdmin
    });

    const handleChangeAdminsFilter = (data: Partial<typeof filter>) => {
        setFilter((state) => ({ ...state, ...data }));
    };

    return {
        admins,
        refetchAdmins,
        admin,
        handleChangeAdminsFilter,
        filter
    };
};
