import React, { FC, useRef, useState } from 'react';
import styles from './styles.module.scss';
import Checkbox from '@components/common/checkbox';
import { TColumn, TFilter, TSort } from '@components/table/index';
import { Icon } from '@components/icons';
import classNames from 'classnames/bind';
import { createPortal } from 'react-dom';

interface IProps {
    column: TColumn;
    handleFilterChange: (key: string, value: string, column: TColumn) => void;
    handleSortChange: (key: string) => void;
    sort?: TSort;
    filter?: TFilter;
    isActions?: boolean;
}

const cx = classNames.bind(styles);

const HeadItem: FC<IProps> = ({
    column,
    filter,
    sort,
    handleSortChange,
    handleFilterChange,
    isActions
}) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const triggerRef = useRef(null);
    const dropdownRef = useRef(null);

    const showDropdown = () => {
        if (!dropdownRef.current) return;
        //@ts-ignore
        const triggerRect = triggerRef.current.getBoundingClientRect();

        // Calculate the position of the dropdown
        const top = triggerRect.bottom + window.scrollY;
        const left = triggerRect.left + window.scrollX;

        // Position the dropdown
        //@ts-ignore
        dropdownRef.current.style.top = `${top}px`;
        //@ts-ignore
        dropdownRef.current.style.position = `absolute`;
        //@ts-ignore
        dropdownRef.current.style.display = `block`;
        //@ts-ignore
        dropdownRef.current.style.left = `${left - 100}px`;
        setIsFilterOpen(true);
    };

    const hideDropdown = () => {
        //@ts-ignore
        dropdownRef.current.style.display = `none`;
        setIsFilterOpen(false);
    };

    return (
        <th
            className={cx([
                { isSort: column.isSort, isActions: isActions || column.isRightAlignment }
            ])}>
            <div onClick={() => column?.isSort && handleSortChange(column.key || '')}>
                <p>{column.label}</p>
                {column.isSort && (
                    <button className={styles.filter_button}>
                        <Icon
                            className={cx([
                                {
                                    isDesc: sort?.key === column.key && sort?.value === 'DESC'
                                }
                            ])}
                            name="nav_down"
                        />
                    </button>
                )}
                {column.filterOptions && (
                    <button
                        className={styles.filter_button}
                        ref={triggerRef}
                        onClick={() => (isFilterOpen ? hideDropdown() : showDropdown())}>
                        <Icon name="filter" />
                    </button>
                )}
                {column.filterOptions &&
                    createPortal(
                        <div
                            className="table_filter_dropdown"
                            style={{
                                position: 'absolute',
                                overflow: !column?.isOverflow ? 'auto' : 'hidden'
                            }}
                            ref={dropdownRef}
                            onMouseLeave={hideDropdown}>
                            {column?.filterOptions?.map((option, i) => {
                                return (
                                    <label className="item" key={i}>
                                        <Checkbox
                                            onClick={() =>
                                                handleFilterChange(
                                                    column.key || '',
                                                    option.value,
                                                    column
                                                )
                                            }
                                            checked={filter?.[column?.key || '']?.includes(
                                                option.value
                                            )}
                                            variant="gold"
                                        />
                                        <p>{option.label}</p>
                                    </label>
                                );
                            })}
                        </div>,
                        document.body
                    )}
            </div>
        </th>
    );
};

export default HeadItem;
