import React, { useState } from 'react';
import Table, { TFilter } from '@components/table';
import styles from './styles.module.scss';
import Status from '@components/common/status';
import Typography from '@components/common/typography';
import Drawer from '@components/drawer';
import Input from '@components/common/input';
import PriceStatus from '@components/common/price_status';
import Button from '@components/common/button';
import { useTransactions } from '@hooks/api/useTransactions';
import { useParams } from 'react-router-dom';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionStatus,
    TTransactionLite
} from '@xeppt/xeppt-sdk/types/transaction';
import {
    getTransactionAction,
    getTransactionMethod,
    getTransactionStatus,
    getTransactionStatusColor
} from '@utils/index';
import moment from 'moment';
import {
    moneyOutActions,
    transactionsMethods,
    transactionsStatuses,
    transactionsType
} from '@const/index';
import DatePicker from '@components/date_picker';

const TransactionsSection = () => {
    const { id: consumerId } = useParams<{ id: string }>();
    const {
        onChangeFilter,
        filter,
        transactions,
        refetchTransactions,
        transactionsData,
        transaction,
        getSingleTransaction,
        handleRemoveTransactionData
    } = useTransactions({ consumerId, isInitialTransactions: true });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const columns = [
        {
            label: 'Date and Time',
            key: 'createdAt',
            isSort: true,
            render: (value: string) => moment(value).format('DD.MM.YYYY HH:mm')
        },
        {
            label: 'Transaction ID',
            key: 'id'
        },
        {
            label: 'Transaction Method',
            key: 'method',
            filterOptions: transactionsMethods,
            render: (value: ETransactionMethod) => getTransactionMethod(value) //
        },
        { label: 'Consumer Name', key: 'consumerName' },
        { label: 'Consumer ID', key: 'consumerId' },
        {
            label: 'Action',
            key: 'action',
            filterOptions: transactionsType,
            render: (value: ETransactionAction) => getTransactionAction(value)
        },
        {
            label: 'Status',
            key: 'status',
            filterOptions: transactionsStatuses,
            render: (value: ETransactionStatus) => (
                <Status variant={getTransactionStatusColor(value)}>
                    {getTransactionStatus(value)}
                </Status>
            )
        },
        { label: 'Currency', key: 'currency' },
        {
            label: 'Amount',
            key: 'amount',
            render: (value: number, data: TTransactionLite) => (
                <PriceStatus
                    isNegative={moneyOutActions.includes(data.action)}
                    amount={value}
                    currency="$"
                />
            )
        },
        {
            label: 'Details',
            key: 'id',
            render: (value: string) => (
                <Button
                    onClick={() => {
                        getSingleTransaction(value).then(() => {
                            setIsDrawerOpen(true);
                        });
                    }}
                    variant="outlined"
                    size="small"
                    style={{ background: 'none' }}>
                    Details
                </Button>
            )
        }
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Transactions</Typography>
                </div>
            </div>
            <Table
                //@ts-ignore
                columns={columns}
                rows={transactions}
                sort={{ key: 'createdAt', value: filter.order }}
                onSortChange={() => {
                    onChangeFilter({
                        order: filter.order === 'DESC' ? 'ASC' : 'DESC'
                    });
                }}
                filter={filter as TFilter}
                onLoadData={refetchTransactions}
                totalRows={transactionsData?.total || 0}
                onFilterChange={(val) => onChangeFilter(val)}
                emptyDescription="Transactions list is empty"
            />
            <Drawer
                className={styles.drawer}
                isOpen={isDrawerOpen && !!transaction}
                width={670}
                onClose={() => {
                    setIsDrawerOpen(false);
                    handleRemoveTransactionData();
                }}>
                <Typography className={styles.title} variant="h5">
                    Transaction details
                </Typography>
                <Input full value={transaction?.id} label="Transaction ID" readOnly />
                <div className={styles.row}>
                    <Input full value={transaction?.consumerId} label="Consumer ID" readOnly />
                    <Input full value={transaction?.consumerName} label="Consumer Name" readOnly />
                </div>
                <div className={styles.row}>
                    <Input
                        full
                        value={getTransactionMethod(transaction?.method)}
                        label="Method"
                        readOnly
                    />
                    <Input
                        full
                        value={getTransactionAction(transaction?.action)}
                        label="Action"
                        readOnly
                    />
                    <Input
                        full
                        value={getTransactionStatus(transaction?.status)}
                        label="Status"
                        readOnly
                    />
                </div>
                <div className={styles.row}>
                    <Input full value={transaction?.currency} label="Currency" readOnly />
                    <Input full value={transaction?.amount} label="Amount" readOnly />
                </div>
                <Input
                    full
                    value={transaction?.automatic ? 'Yes' : 'No'}
                    label="Is bill payment"
                    readOnly
                />
                <div className={styles.row}>
                    <Input full value={transaction?.description} label="Description" readOnly />
                    <Input
                        full
                        value={transaction?.details.destination}
                        label="Destination"
                        readOnly
                    />
                </div>
                <div className={styles.row}>
                    <Input full value={transaction?.details.source} label="Source" readOnly />
                    <DatePicker
                        label="Created at"
                        full
                        //@ts-ignore
                        value={transaction?.createdAt as string}
                        readOnly
                    />
                </div>
            </Drawer>
        </div>
    );
};

export default TransactionsSection;
