import { EUserStatus } from '@xeppt/xeppt-sdk/types';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionStatus
} from '@xeppt/xeppt-sdk/types/transaction';

export const paymentSystems = {
    visa: {
        name: 'VISA'
        // Icon: <VisaIcon />,
    },
    mastercard: {
        name: 'MasterCard'
        // Icon: <VisaIcon />,
    }
};

export const supportedLanguages = ['en', 'fr'];

export const userStatuses = [
    {
        label: 'Suspended',
        value: EUserStatus.SUSPENDED
    },
    {
        label: 'Active',
        value: EUserStatus.ACTIVE
    },
    {
        label: 'Pending',
        value: EUserStatus.PENDING
    },
    {
        label: 'Unverified',
        value: EUserStatus.UNVERIFIED
    },
    {
        label: 'Unconfirmed',
        value: EUserStatus.UNCONFIRMED
    },
    {
        label: 'Blocked',
        value: EUserStatus.BLOCKED
    }
];

export const errorCodes = [
    'INVALID_STRING_FORMAT',
    'MUST_BE_A_NUMBER',
    'INVALID_ISO_CODE',
    'INVALID_DATE_FORMAT',
    'INVALID_URL_FORMAT',
    'LENGTH',
    'MIN_LENGTH',
    'MAX_LENGTH',
    'EFT_ACCOUNT_EXISTS',
    'MUST_BE_EQUAL',
    'MUST_BE_ONE_OF',
    'INVALID_REQUEST',
    'NOT_FOUND',
    'USER_PHONE_EXIST',
    'USER_ALREADY_ACTIVATED',
    'AUTH_ERROR',
    'AUTH_INVALID_CREDENTIALS',
    'UNAUTHORIZED',
    'ACCESS_DENIED',
    'BANK_ACCOUNT_NOT_FOUND',
    'BANK_ACCOUNT_UPDATE_FAILED',
    'DEBIT_FUNDS_FAILED',
    'CREDIT_FUNDS_FAILED',
    'ACCOUNT_INSUFFICIENT_BALANCE',
    'FIND_EFT_ACCOUNT_ERROR',
    'LINK_EFT_ACCOUNT_ERROR',
    'EFT_ACCOUNT_EXISTS',
    'LINK_E_TRANSFER_ACCOUNT_ERROR',
    'E_TRANSFER_ACCOUNT_EXISTS',
    'UNSUPPORTED_METHOD',
    'FIND_E_TRANSFER_ACCOUNT_ERROR',
    'BILLING_PAYEE_NOT_FOUND',
    'BILLING_PAYEE_LIST_FAILED',
    'BILLING_PAYEE_CREATION_FAILED',
    'BILLING_PAYEE_UPDATE_FAILED',
    'BILLING_PAYMENT_NOT_FOUND',
    'BILLING_PAYMENT_LIST_FAILED',
    'BILLING_PAYMENT_CREATION_FAILED',
    'BILLING_PAYMENT_STATUS_UPDATE_FAILED',
    'BILLING_PAYMENT_UPDATE_FAILED',
    'CREATE_CONTACT_ERROR',
    'CONTACT_EXISTS',
    'CONTACT_NOT_FOUND',
    'DECODE_STRING_FAILED',
    'NEW_SESSION_FAILED',
    'PUT_OBJECT_FAILED',
    'PATENO_CARD_NOT_FOUND',
    'DECODE_PEM_FAILED',
    'PARSE_DER_FAILED',
    'RSA_KEY_ERROR',
    'ENCRYPT_FAILED',
    'SET_PIN_FAILED',
    'CREATE_WALLET_FAILED',
    'CREATE_PHYSIC_CARD_FAILED',
    'GET_CARD_DETAILS_FAILED',
    'INSTITUTION_NOT_FOUND',
    'BRANCH_NOT_FOUND',
    'CUSTOMER_CREATE_FAILED',
    'CUSTOMER_UPDATE_FAILED',
    'COUNTRY_LIST_FAILED',
    'PROVINCE_LIST_FAILED',
    'CITY_LIST_FAILED',
    'CREATE_EFT_FAILED',
    'CREATE_E_TRANSFER_FAILED',
    'CREATE_SUPPLEMENTARY_CARD_FAILED',
    'CREATE_CONTACT_FAILED',
    'CARD_TRANSACTIONS_ERROR',
    'BILL_PAYMENT_FAILED',
    'TRANSACTION_CREATE_FAILED',
    'TRANSACTION_NOT_FOUND',
    'EFT_TRANSACTION_NOT_FOUND',
    'ETRANSFER_TRANSACTION_NOT_FOUND',
    'USER_NOT_FOUND',
    'USER_NOT_CREATED',
    'PHONE_NOT_FOUND',
    'EMAIL_NOT_FOUND',
    'EMAIL_EXISTS',
    'PHONE_EXISTS',
    'EMAIL_IS_PRIMARY',
    'PHONE_IS_PRIMARY',
    'PHONE_NOT_VERIFIED',
    'EMAIL_NOT_VERIFIED',
    'ADDRESS_NOT_FOUND',
    'INVALID_VERIFICATION_CODE',
    'USER_ALREADY_ACTIVATE',
    'INVALID_ACTIVATION_CODE',
    'INVALID_RESET_TOKEN',
    'USER_VERIFICATION_FAILED',
    'INVALID_PASSWORD',
    'USER_VERIFICATION_NOT_FOUND',
    'SETTINGS_NOT_FOUND',
    'WALLET_CARD_NOT_FOUND',
    'ACTIVATE_CARD_FAILED',
    'REPLACE_CARD_FAILED',
    'UPDATE_CARD_LIMIT_FAILED',
    'REQUEST_CARD_FAILED',
    'MAX_LIMIT_REACHED'
];

export const moneyOutActions = [
    ETransactionAction.TRANSACTION_FEE,
    ETransactionAction.SEND_MONEY,
    ETransactionAction.BILL_PAYMENT
];

export const transactionsType = [
    { label: 'Send Money', value: ETransactionAction.SEND_MONEY },
    { label: 'Transaction fee', value: ETransactionAction.TRANSACTION_FEE },
    { label: 'Bill Payment', value: ETransactionAction.BILL_PAYMENT },
    { label: 'Request Card', value: ETransactionAction.REQUEST_CARD },
    { label: 'Receive money', value: ETransactionAction.RECEIVE_MONEY },
    { label: 'Load Balance', value: ETransactionAction.LOAD_BALANCE }
];

export const transactionsMethods = [
    { label: 'Bank', value: ETransactionMethod.EFT },
    { label: 'Wallet', value: ETransactionMethod.WALLET },
    { label: 'E-Transfer', value: ETransactionMethod.E_TRANSFER },
    { label: 'Bill Payment', value: ETransactionMethod.BILL },
    { label: 'Card', value: ETransactionMethod.CARD }
];

export const transactionsStatuses = [
    { label: 'Approved', value: ETransactionStatus.APPROVED },
    { label: 'Canceled', value: ETransactionStatus.CANCELED },
    { label: 'Pending', value: ETransactionStatus.PENDING },
    { label: 'Error', value: ETransactionStatus.ERROR },
    { label: 'Declined', value: ETransactionStatus.DECLINED }
];
