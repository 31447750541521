import { useState } from 'react';
import { apiAdminService } from '@api';
import { EConsumerStatus } from '@xeppt/xeppt-sdk/types/consumer';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useParams } from 'react-router-dom';
import { TConsumerLite } from '@xeppt/xeppt-sdk/types';
import useDebounce from '@hooks/helpers/useDebounce';

interface IProps {
    isInitialConsumers?: boolean;
    isInitialConsumer?: boolean;
    isInitialConsumerCard?: boolean;
    isInitialConsumerCards?: boolean;
}

export const useConsumers = ({
    isInitialConsumers = false,
    isInitialConsumer = false,
    isInitialConsumerCards = false
}: IProps) => {
    const [consumersCursor, setConsumersCursor] = useState<Date>();
    const [consumers, setConsumers] = useState<TConsumerLite[]>([]);
    const { id } = useParams();
    const [consumersFilter, setConsumersFilter] = useState<{
        status?: EConsumerStatus[];
        limit?: number;
        search?: string;
        order: 'ASC' | 'DESC';
    }>({
        status: undefined,
        limit: 100,
        order: 'ASC'
    });

    const debouncedFilter = useDebounce(consumersFilter, 500);

    const { data: consumersData, handleRequest: refetchConsumers } = useApiQuery({
        method: () => {
            return apiAdminService.getConsumers(
                {
                    status: consumersFilter.status,
                    order: consumersFilter.order,
                    search: consumersFilter.search
                },
                {
                    cursor: consumersCursor,
                    limit: consumersFilter.limit
                }
            );
        },
        onSuccess: (data) => {
            if (consumersCursor !== data.cursor) {
                setConsumers((state) => [...state, ...data.data]);
                setConsumersCursor(data.cursor);
            } else {
                setConsumers(data.data);
            }
        },
        deps: [debouncedFilter],
        isInitialRequest: isInitialConsumers
    });

    const { data: consumer } = useApiQuery({
        method: () => apiAdminService.getConsumerById(id || ''),
        condition: !!id,
        deps: [id],
        isInitialRequest: isInitialConsumer
    });

    const {
        data: consumerCard,
        handleRequest: getConsumerCardById,
        handleRemoveResponse: handleRemoveCard
    } = useApiQuery({
        method: (id?: string) => apiAdminService.getConsumerCardById(id || ''),
        isInitialRequest: false
    });

    const { data: consumerCards } = useApiQuery({
        method: () => apiAdminService.getConsumerCards({ id: id || '' }),
        condition: !!id,
        deps: [id],
        isInitialRequest: isInitialConsumerCards
    });

    const handleChangeConsumersFilter = (data: Partial<typeof consumersFilter>) => {
        setConsumersCursor(undefined);
        setConsumersFilter((state) => ({ ...state, ...data }));
    };

    return {
        consumers,
        consumer,
        consumerCards,
        refetchConsumers,
        consumerCard,
        consumersFilter,
        consumersData,
        getConsumerCardById,
        handleChangeConsumersFilter,
        handleRemoveCard
    };
};
